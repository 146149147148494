// assets/app.js
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

// start the Stimulus application
//import './bootstrap';

const $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
//require('bootstrap');
//require('./vendor/smooth-scroll/dist/smooth-scroll.polyfills.min.js');

import "./vendor/bootstrap/dist/js/bootstrap.bundle.min.js";
import "./vendor/simplebar/dist/simplebar.min.js";
//import "./vendor/tiny-slider/dist/min/tiny-slider.js";
//import "./vendor/smooth-scroll/dist/smooth-scroll.polyfills.min.js";
//import SmoothScroll from "./vendor/smooth-scroll/dist/smooth-scroll.js";

import "./vendor/drift-zoom/dist/Drift.min.js";
import './js/theme.js';
//import "./cartzilla3/src/js/theme.js";
// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');

$(document).ready(function() {
    $('[data-toggle="popover"]').popover();
});

